
h3 {
    font-family: 'Proxima Nova';
    text-align: center;
}

body {
    font-size: 1em;
}


.alice-carousel {
    width: 90%; 
}


#logomotion_figure_gallery {
    width: 100%;
    margin-top:2em;
    background-color: white;
    border-top: solid 1px deeppink;
    padding:1em;
}

.logomotion_figure {
    border-radius:0.25em;
    padding: 2em;
    width:70% !important;

}

.two_column {
    display:flex
}


.left_half {
    width: 50%;

}
.right_half {

    width: 50%;
}

.three_column {
    display:flex;
}


.third1 {
    width: 29.5%;
}
.third2 {
    width: 29.5%;
}
.third3 {
    width: 41%;
}

.caption {
    text-align: justify;
    margin:0.5em;
}
.logomotion_header {
    text-align:left;
}
.right_column {
    padding:2em;

}
.logomotion_column_figure {
    border-radius:0.25em;
    padding: 2em;
    width: 100% !important;


}
.carouselContent {
    width: 512px;
}

.navigation {
    text-align: center;
    margin-top: 2em;
}

.navigation a {
padding: 1em;
font-size: 16px;
text-decoration: none;
color: #333333;

}

